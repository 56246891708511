@tailwind base;

@layer base {
  h1 {
    @apply text-2xl;
  }
  h2 {
    @apply text-xl;
  }
  h3 {
    @apply text-lg;
  }
  a {
    @apply text-blue-600 underline;
  }
  button {
    @apply mt-1  px-1 ;
  }
  button:hover {
    @apply bg-gray-300;
  }
}
@tailwind components;

@layer components {
  .styledButton {
    @apply bg-blue-500 hover:bg-blue-700 text-white h-5 mr-4 pr-6 pl-4 text-sm;
  }
  .styledButton-inactive {
    @apply bg-gray-500 hover:bg-blue-700 text-white h-5 mr-4 pr-6 pl-4 text-sm;
  }
}

@tailwind utilities;